import * as React from 'react';
import { navigate, Link } from "gatsby"
import { 
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Container,
	Grid,
	Skeleton,
	Typography,
} from '@mui/material';
import { 
	Info,
} from '@mui/icons-material';
import PapInput from './pap-input.js';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoDowin from "images/logodowin.png";
import HandsMobile from "images/handsMobile.png";
import HandBlue from "images/handBlue.png";
import ExampleAppMobile from "images/exampleAppMobile.png";

// styles
const pageStyles = {
	fontFamily: 'Quicksand',
};
const topBar = {
	height: '56px',
	marginTop: '0px',
    paddingLeft: '16px',
}
const imageContainer = {
	height: '36.7px'
}
const pageMargin = {
	justifyContent: 'center',
	paddingLeft: '40px',
	paddingRight: '35px',
}
const headerMargin = {
	marginBottom: '20px',
	marginLeft: '16px',
	display: 'inline-block',
	verticalAlign: 'middle',
}
const slogan = {
	fontFamily: 'Quicksand',
	fontSize: '16px',
	lineHeight: '15px',
	alignItems: 'center'
}

const sloganDetail = {
	fontFamily: 'Quicksand',
	color: '#979797',
	fontSize: '12px',
	lineHeight: '12px',
	alignItems: 'center',
}
const verticalPadding20 = {
	paddingTop: '20px',
	paddingBottom: '20px',
}
const paddingHorizontal20 = {
	paddingRight: '20px',
	paddingLeft: '20px',
}
const orgBox = {
	backgroundColor: '#C6DCE8',
}
const donorBox = {
	backgroundColor: '#3239E5',
}
const promoContainer = {
	paddingTop: '16px',
	//paddingLeft: '5px',
	//paddingRight: '5px',
	paddingBottom: '20px',
}
const darkBoxTitle = {
	fontFamily: 'Quicksand',
	color: '#CCEDFF',
	fontSize: '16px',
	lineHeight: '15px',
	fontWeight: "700",
}
const darkBoxSubtitle = {
	fontFamily: 'Quicksand',
	color: '#CCEDFF',
	fontSize: '14px',
	lineHeight: '14px',
	fontWeight: "400",
	paddingBottom: '12px',
	paddingTop: '12px',
}
const darkBoxText = {
	fontFamily: 'Quicksand',
	fontSize: '14px',
	lineHeight: '16px',
	color: '#FFFFFF',
}
const lightBoxTitle = {
	fontFamily: 'Quicksand',
	fontSize: '14px',
	lineHeight: '15px',
	fontWeight: '700',
}
const lightBoxSubtitle = {
	fontFamily: 'Quicksand',
	fontSize: '14px',
	lineHeight: '12px',
	fontWeight: "400",
	paddingTop: '12px',
	paddingBottom: '26px',
}
const blackButton = {
	fontFamily: 'Quicksand',
	fontSize: '12px',
	lineHeight: '12px',
	fontWeight: "700",
	backgroundColor: '#000000',
	width: '100%',
	borderRadius: '50px',
	paddingLeft: '5px',
	paddingRight: '5px',
	paddingTop: '10px',
	paddingBottom: '10px',
}
const paleBlueButton = {
	fontFamily: 'Quicksand',
	fontSize: '12px',
	lineHeight: '12px',
	fontWeight: "700",
	backgroundColor: '#C6DCE8',
	color: '#3239E5',
	width: '100%',
	textAlign: 'center',
	borderRadius: '50px',
	paddingLeft: '5px',
	paddingRight: '5px',
	paddingTop: '10px',
	paddingBottom: '10px',
}
const lightBlueButton = {
	fontFamily: 'Quicksand',
	fontSize: '12px',
	lineHeight: '10px',
	fontWeight: "700",
	backgroundColor: '#2C90CB',
	color: '#FFFFFF',
	width: '100%',
	borderRadius: '50px',
	paddingLeft: '5px',
	paddingRight: '5px',
	paddingTop: '12px',
	paddingBottom: '12px',
}
const lightBlueText = {
	fontFamily: 'Quicksand',
	fontSize: '12px',
	lineHeight: '12px',
	fontWeight: '500',
	color: '#2C90CB',
	textAlign: 'left',
}
const lightBlueTextButton = {
	fontFamily: 'Quicksand',
	fontSize: '12px',
	lineHeight: '12px',
	fontWeight: '500',
	color: '#2C90CB',
	width: '100%',
}
const darkBlueText = {
	fontFamily: 'Quicksand',
	fontSize: '12px',
	lineHeight: '12px',
	fontWeight: '500',
	color: '#CCEDFF',
	textAlign: 'left',
}
const lightBoxText = {
	fontFamily: 'Quicksand',
	fontSize: '14px',
	lineHeight: '14px',
}
const maxWidth420 = {
	maxWidth: '420px'
}
const formContainer = {
	width: '100%',
	marginTop: '8px',
}
const inputStyle = {
	width: '100%',
	backgroundColor: '#FFFFFF',
	borderRadius: '12px',
	borderWidth: '0px',
	fontFamily: 'Quicksand',
	fontSize: '14px',
	lineHeight: '14px',
	marginBottom: '8px',
}
const inputLabelStyle = {
	fontFamily: 'Quicksand',
	fontSize: '14px',
	lineHeight: '24px',
	borderRadius: '5px',
	color: '#979797',
}
const top8 = {
	paddingLeft: '0px',
	paddingRight: '0px',
	marginTop: '8px',
}
const top32 = {
	paddingLeft: '0px',
	paddingRight: '0px',
	marginTop: '32px',
}
const top22 = {
	paddingLeft: '0px',
	paddingRight: '0px',
	marginTop: '22px',
}
const bigImageContainer = {
	margin: '0 auto',
	marginTop: '48px',
}
const appImgResize = {
	objectFit: 'contain',
	width: 'auto',
	height: '300px',
}
const imgResize = {
	maxWidth: '100px',
	height: 'auto',
}
const titleR400 = {
	fontFamily: 'Quicksand',
	fontSize: '14px',
	lineHeight: '15px',
	fontWeight: "400",
}
const noBoxShadow = {
	boxShadow: 'none'
}
const faqTitle = {
	fontFamily: 'Quicksand',
	fontSize: '14px',
	lineHeight: '14px',
	fontWeight: "500",
	textAlign: 'left',
}
const faqText = {
	fontFamily: 'Quicksand',
	fontSize: '12px',
	lineHeight: '12px',
	fontWeight: "400",
	textAlign: 'left',
}

const faqs = [
    {
        title: "¿Qué es dowin?",
        content: "dowin es una aplicación de Premium&Premium que permite que las organizaciones (Fundaciones, ONGs, asociaciones, confederaciones, y otras organizaciones con fines sociales, deportivas o educativas) puedan financiar sus proyectos y al mismo tiempo recompensar a las personas que donan."
    },
    {
        title: "¿Qué es Premium&Premium?",
        content: "Premium&Premium es una startup focalizada en el emprendimiento social cuyo objetivo principal es ayudar a realizar PROYECTOS SOLIDARIOS. Esta startup participa en varios proyectos solidarios y varias actividades de carácter social. dowin es su primera aplicación para donaciones masivas."
    },
    {
        title: "¿Qué pasa con el dinero que estoy donando?",
        content: "El dinero que cada uno decide donar a las organizaciones para financiar la propia organización o un proyecto en particular, va íntegramente a la organización. Premium&Premium cobra a la organización una cantidad por el uso de la plataforma dowin que permite poner en contacto a la organización con los donantes."
    },
    {
        title: "¿Estoy donando dinero o estoy comprando un boleto para ganar un premio?",
        content: "dowin es una plataforma donde donar dinero a las organizaciones, sin embargo, consideramos que se hace más interesante y atractivo, y las organizaciones pueden tener muchos más recursos para poder llevar a cabo sus proyectos, si hay detrás un incentivo para las personas que donan. Por lo tanto, se realiza una donación y como mecanismo incentivador cada donación de una cantidad determinada podrá obtener una recompensa si son afortunados."
    },
    {
        title: "¿Quién ofrece el premio a los donantes?",
        content: "Tanto las organizaciones a las que podéis donar el dinero como Premium&Premium  son las que se encargan de ofrecer un premio a los donantes. En caso de la celebración de un sorteo, son las organizaciones tras la pertinente autorización legal de la Direccion General de la Ordenación del juego, las que se encargan de todo lo relacionado con el sorteo. dowin sólo es una plataforma de intermediación para facilitar la donación y no participa en ninguno de los aspectos del sorteo, más allá de ser la plataforma que facilita las donaciones y donde se pueden ver los boletos e informar del resultado del sorteo. La organización es la encargada de contactar con los beneficiarios y entregarles su premio, y la responsable última de todos los requisitos legales para poder hacerlo. En caso de que en lugar de un sorteo, se ofrezca un incentivo promocional a todos los donates, podrá ser Premium&Premium la que se encargue de dicha promoción."
    },
    {
        title: "¿Cómo funcionan los regalos promocionales organizados por Premium&Premium?",
        content: "Si yo quiero donar, por ejemplo, 5€ (mínima cantidad aceptada) a una organización, esta organización me dará un boleto para tener la posibilidad de ganar un regalo promocional de, por ejemplo, un fin de semana en un balneario. Si quiero donar 50€ pues recibiré en mi cartera 10 boletos que me permitirán ganar 10 fines de semana en un balneario. Todas las personas que han donado podrán ganar ese premio."
    },
    {
        title: "¿Cómo funcionan los sorteos organizados por las organizaciones?",
        content: "Si yo quiero donar, por ejemplo, 5€ (mínima cantidad aceptada) a una organización, esta organización me dará un boleto para participar en un sorteo de un fin de semana en un balneario. Si quiero donar 50€ pues recibiré en mi cartera 10 boletos para el sorteo. Sólo una o algunas de las personas que han donado podrá ganar ese premio. Las organizaciones que organicen los sorteos, previamente autorizadas por la Direccion General de Ordenación del Juego, seleccionarán los ganadores ante notario y se notificará el ganador a través de la aplicación."
    },
    {
        title: "¿Puedo donar dinero a un proyecto y no participar en ningún regalo promocional o sorteo?",
        content: "Por supuesto. Desde Premium&Premium insistimos en que el fin de la aplicación es ayudar a las organizaciones a buscar recursos. Los regalos que se puedan ganar son algo complementario para incentivar las donaciones. También existe la opción de donar el dinero y regalar los boletos a la organización, de tal manera que, si toca, sería la organización la beneficiaria. Del mismo modo se puede renunciar al premio a posteriori a favor de la organización."
    },
    {
        title: "¿Puedo solicitar la devolución del dinero después de haber realizado la donación?",
        content: "Por supuesto. Si te arrepientes de haber realizado la donación o te has equivocado en la cantidad donada o tienes cualquier otra incidencia con la donación realizada, puedes contactar con info@premiumandpremium.com durante las 24 horas siguientes a la realización de la donación y resolveremos tú problema. Una vez transcurrido este tiempo ya no se podrá retrotraer la operación."
    },
    {
        title: "¿Si cargo más dinero en el wallet de lo que he decidido donar, puedo recuperar el dinero?",
        content: "Puedes recuperar tu dinero de una manera rápida y sencilla. El dinero que está en tu wallet es tuyo y tan sólo tienes que apretar al botón que existe la propia wallet de tu aplicación y el dinero te será devuelto en un periodo de 24/48 horas."
    },
/*
    {
        title: "¿Puedo regalar parte de mis boletos a otros usuarios de dowin?",
        content: "Por supuesto. Tan sólo tendrás que acudir en la aplicación a la opción de “regalar mis boletos” y seguir los pasos que se marcarán."
    },
    {
        title: "¿Puedo regalar parte de mis boletos a otras personas que aún no sean usuarios de dowin?",
        content: "Exactamente igual que si las personas fueran usuarios de dowin. Eso sí, para poder recibir los boletos, aquellas personas a las que quieras regalar los boletos deberán darse de alta como usuarios en la aplicación."
    },
*/
]

export default function PapIndexMobile(props) {
    return (
        <Box style={pageStyles}>
				<Grid container spacing={1} style={topBar}>
					<Grid item xs={12}>
						<Link to="/">
							<img
								alt="dowin Logo"
								style={imageContainer}
								src={LogoDowin}
							/>
						</Link>
						<Box style={headerMargin}>
							<Typography fontSize={"12px"} fontFamily={"Quicksand"} display={"inline"}> una app de </Typography>
							<Typography fontSize={"12px"} fontFamily={"Quicksand"} display={"inline"} fontWeight="700">Premium&Premium</Typography>
						</Box>
					</Grid>
				</Grid>
				<Grid style={verticalPadding20} container spacing={2}>
					<Grid item paddingTop={"20px"} textAlign={"center"} xs={6} md={5}>
						<img
							alt="app image example"
							style={appImgResize}
							src={ExampleAppMobile}
						/>
					</Grid>
					<Grid item style={paddingHorizontal20} xs={6} md={7}>
							<Typography style={slogan} display={"inline"} fontWeight="700">Dona</Typography>
							<Typography style={slogan} display={"inline"}> a proyectos solidarios y </Typography>
							<Typography style={slogan} display={"inline"} fontWeight="700"> gana </Typography>
							<Typography style={slogan} display={"inline"}> premios </Typography>
							<Typography style={slogan} display={"inline"} fontWeight="700">ayudando.</Typography>
							<br/>
							<br/>
							<Typography style={sloganDetail} display={"inline"}>🤝 En Premium&Premium ayudamos a entidades sin ánimo de lucro, de caracter social, educativo o deportivo a captar recursos para financiar sus </Typography>
							<Typography style={sloganDetail} display={"inline"} fontWeight="700">PROYECTOS SOLIDARIOS.</Typography>
					</Grid>
				</Grid>
				<Grid style={verticalPadding20} container spacing={2}>
					<Grid item style={orgBox} xs={6}>
						<Container disableGutters={true} style={promoContainer}>
							<Grid xs={12} container >
								<Grid xs={1} item ></Grid>
								<Grid xs={10} item >
									<Typography style={lightBoxTitle} fontWeight="700">¿Necesitas fondos?</Typography>
									{props.values.loading.user ?
										<Grid pt={3} pl={2}justifyContent={'left'} container spacing={2}>
											<Skeleton variant="text" animation="wave" width={'30%'} height={30} />
											<Skeleton variant="text" animation="wave" width={'100%'} height={70} />
											<Box width={'100%'} height={20}></Box>
											<Skeleton variant="rectangle" animation="wave" width={'80%'} height={200} />
											<Skeleton variant="text" animation="wave" width={'100%'} height={80} />
										
											<Skeleton variant="text" animation="wave" width={'60%'} height={30} />
											<Skeleton variant="text" animation="wave" width={'100%'} height={70} />
											<Skeleton variant="text" animation="wave" width={'100%'} height={70} />
											<Skeleton variant="text" animation="wave" width={'100%'} height={70} />
											<Box width={'20%'} height={20}></Box><Skeleton variant="text" animation="wave" width={'60%'} height={50} /><Box width={'20%'} height={20}></Box>
											<Box width={'20%'} height={20}></Box><Skeleton variant="text" animation="wave" width={'60%'} height={50} /><Box width={'100%'} height={20}></Box>
										</Grid>
									:
									<>
									{props.values.isLogged ? 
										<>
											<Typography style={lightBoxSubtitle}>Accede a tu área privada:</Typography>
											<Button style={blackButton} onClick={() => navigate("dashboard")} variant="contained">Acceder a mi área privada</Button>
											<Typography pt={"47px"} style={lightBoxText}>Podemos ayudar a:</Typography>
											<ul>
												<li>
													<Typography style={lightBoxText}>Ongs y Fundaciones</Typography>
												</li>
												<li>
													<Typography style={lightBoxText}>Entidades deportivas</Typography>
												</li>
												<li>
													<Typography style={lightBoxText}>Entidades educativas</Typography>
												</li>
												<li>
													<Typography style={lightBoxText}>Víctimas de catástrofes naturales</Typography>
												</li>
												<li>
													<Typography style={lightBoxText}>Mecenazgo de deportistas</Typography>
												</li>
											</ul>
											<Typography style={lightBoxText} display={"inline"} fontWeight="700">Cualquier persona puede solicitar fondos </Typography>
											<Typography style={lightBoxText} display={"inline"}>para la causa solidaria que desee.</Typography>
											<Grid container direction={"column"} justifyContent={'center'} sx={{textAlign: 'center'}}>
												<Container style={bigImageContainer}>
													<img
														alt="hand and heart"
														style={imgResize}
														src={HandBlue}
													/>
												</Container>
												<Grid container sx={[top8, {justifyContent: 'center', alignItems: 'center'}]}>
													<Grid item xs={10}>
														<Info sx={[lightBlueText, {fontSize: 20 }]}/>
														<Button style={lightBlueText} onClick={() => navigate("solicitants")} >Más información para solicitantes</Button>
													</Grid>
												</Grid>
											</Grid>
										</>
										:
										<>
											<Typography style={lightBoxSubtitle}>Solo inscríbete aquí:</Typography>
											<Button style={blackButton} onClick={() => navigate("register")} variant="contained">Inscríbete como solicitante</Button>
											<Typography pt={"16px"} style={lightBoxText}>Podemos ayudar a:</Typography>
											<ul style={{paddingLeft: "15px"}}>
												<li>
													<Typography style={lightBoxText}>Ongs y Fundaciones</Typography>
												</li>
												<li>
													<Typography style={lightBoxText}>Entidades deportivas</Typography>
												</li>
												<li>
													<Typography style={lightBoxText}>Entidades educativas</Typography>
												</li>
												<li>
													<Typography style={lightBoxText}>Víctimas de catástrofes naturales</Typography>
												</li>
												<li>
													<Typography style={lightBoxText}>Mecenazgo de deportistas</Typography>
												</li>
											</ul>
											<Typography style={lightBoxText} display={"inline"} fontWeight="700">Cualquier persona puede solicitar fondos </Typography>
											<Typography style={lightBoxText} display={"inline"}>para la causa solidaria que desee.</Typography>
											<Typography pt={"16px"} pb={"8px"} style={lightBoxText}>¿Ya eres solicitante?</Typography>
											<PapInput
												label="Email" 
												value={props.values.email}
												id="inputEmail"
												isPassword={false}
												onChange={props.handleChange('email')}
												inputStyle={inputStyle}
												inputLabelStyle={inputLabelStyle}
											/>
											<PapInput
												label="Contraseña" 
												value={props.values.password}
												id="inputPassword"
												isPassword={true}
												onChange={props.handleChange('password')}
												inputStyle={inputStyle}
												inputLabelStyle={inputLabelStyle}
											/>
											<Container style={top8}>
												<Button onClick={props.performLogin} style={lightBlueButton} variant="contained">Iniciar sesión</Button>
											</Container>
											<Container style={top8}>
												<Button onClick={props.handleClickForgottenPass} style={lightBlueTextButton}>¿Has olvidado tu contraseña?</Button>
											</Container>
											<Grid container sx={[top8, {justifyContent: 'center', alignItems: 'center'}]}>
												<Grid item xs={10}>
													<Info sx={[lightBlueText, {fontSize: 20 }]}/>
													<Button style={lightBlueText} onClick={() => navigate("solicitants")} >Más información para solicitantes</Button>
												</Grid>
											</Grid>
										</>
									}
									</>
								}
								</Grid>
								<Grid xs={1} item ></Grid>
							</Grid>
						</Container>
					</Grid>
					<Grid item style={donorBox} xs={6}>
						<Container disableGutters={true} style={promoContainer}>
							<Container styles={maxWidth420}>
								<Typography style={darkBoxTitle}>¿Quieres donar?</Typography>
								<Typography style={darkBoxSubtitle}>Solo descarga la aplicación:</Typography>
								<Button style={paleBlueButton} onClick={() => navigate('/downloadApp')} variant="contained">Descargar ya la app dowin</Button>
								<Container style={top22}>
									<Typography style={darkBoxText} display={"inline"} >Carga tu cartera y comienza a donar, además, todas las donaciones realizadas serán recompensadas con la posibilidad de </Typography>
									<Typography style={darkBoxText} display={"inline"} fontWeight="700">ganar una gran cantidad de premios.</Typography>
								</Container>
								<Container style={top22}>
									<Typography style={darkBoxText}>No donas por ganar, pero puedes ver recompensada tu generosidad.</Typography>
								</Container>
								<Grid container direction={"column"} justifyContent={'center'} sx={{textAlign: 'center'}}>
									<Container style={bigImageContainer}>
										<img
											alt="hands and heart"
											style={imgResize}
											src={HandsMobile}
										/>
									</Container>
									<Grid container sx={[top32, {justifyContent: 'center', alignItems: 'center'}]}>
										<Grid item xs={10}>
											<Info sx={[darkBlueText, {fontSize: 20 }]}/>
											<Button style={darkBlueText} onClick={() => navigate("donors")} >Más información para donantes</Button>
										</Grid>
										<Grid container xs={1} md={2}></Grid>
									</Grid>
								</Grid>
							</Container>
						</Container>
					</Grid>
					<Box style={pageMargin}>
						<Grid container style={verticalPadding20} textAlign={'center'}>
							<Grid item xs={12}>
								<Box >
									<Typography style={titleR400} display={"inline"}>Tanto si solicitas fondos para un </Typography>
									<Typography style={lightBoxTitle} display={"inline"}>proyecto solidario </Typography>
									<Typography style={titleR400} display={"inline"}>como si eres un  </Typography>
									<Typography style={lightBoxTitle} display={"inline"}>donante involucrado, </Typography>
									<Typography style={titleR400} display={"inline"}>tu plataforma es:</Typography>
								</Box>
							</Grid>
						</Grid>
						<Grid style={verticalPadding20} textAlign={'center'} container spacing={2}>
							<Grid item alignContent={'center'} xs={12}>
								<img
									alt="dowin logo"
									style={imgResize}
									src={LogoDowin}
								/>
							</Grid>
						</Grid>
						<Grid style={verticalPadding20} textAlign={'center'} container spacing={2}>
							<Grid item alignContent={'center'} xs={12}>
								<Container>
									{faqs.map((faq, index) =>
										<Accordion style={noBoxShadow} key={`faq${index}`}>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon/>}
												aria-controls={`panel${index}-content`}
												id={`panel${index}-header`}
											>
												<Typography style={faqTitle}>{faq.title}</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Typography style={faqText}>{faq.content}</Typography>
											</AccordionDetails>
										</Accordion>
									)}
								</Container>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Box>
    );
}