import * as React from 'react';
import { auth } from '../../firebase-info';
import { 
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
} from '@mui/material';
import PapAlert from 'components/pap-alert.js';
import PapFooter from 'components/pap-footer.js';
import PapIndexDesktop from 'components/pap-index-desktop.js';
import PapIndexMobile from 'components/pap-index-mobile.js';
import { navigate } from 'gatsby';
import MediaQuery from 'react-responsive';
import CookieConsent from "react-cookie-consent";
// styles
const Quicksand = {
	fontFamily: 'Quicksand',
}

const cookieLinkStyle = {
	fontFamily: 'Quicksand',
	color: '#2C90CB',
	fontSize: '24px',
	lineHeight: '32px',
	fontWeight: '500',

}

// markup
const IndexPage = () => {
	const [values, setValues] = React.useState({
		email: '',
		emailRecovery: '',
		password: '',
		showPassword: false,
		isLogged: false,
		loading: {
			user: true,
		}
	  });
	const [alertBox, setAlertBox] = React.useState(null);
	const [open, setOpen] = React.useState(false);

	function checkLogin() {
		auth.onAuthStateChanged(function(user) {
			setValues({ ...values, 
				'isLogged': (user !== null), 
				'loading': {
					user: false
				} 
			});
		  });
	};

	const handleMediaQueryChange = (matches) => {
		return matches;
	}
  
	React.useEffect(() => {
		checkLogin();
	}, []);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		values.emailRecovery = '';
		setOpen(false);
	};

	const handleRecovery = () => {
		auth.sendPasswordResetEmail(values.emailRecovery)
			.then((ok) => {
				setAlertBox({
					severity: 'success',
					message: 'Hemos enviado un correo para reestablecer tu contraseña.'
				  })
			})
			.catch((error) => {
				setAlertBox({
					severity: 'success',
					message: 'Hemos enviado un correo para reestablecer tu contraseña.'
				  })
			})
		setOpen(false)
		setValues({ ...values, 'emailRecovery': '' });
		setTimeout(function () { setAlertBox(null) }, 3500);
	}

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	  };
	

	const performLogin = async () => {
		try {
			const response = await auth.signInWithEmailAndPassword(values.email, values.password);
			navigate('/dashboard');
		} catch (error) {
			setAlertBox({
				severity: 'error',
				message: 'Usuario o contraseña incorrectos, por favor, vuelva a intentarlo'
			})
	  
			setTimeout(function () { setAlertBox(null) }, 3500);
		}
	}

	return (
		<main>
			{
				alertBox ? (
					<PapAlert
						severity={alertBox.severity}
						message={alertBox.message}
					/>
				) : null
			}
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle fontFamily={"Quicksand"}>Recuperar contraseña</DialogTitle>
				<DialogContent>
				<DialogContentText fontFamily={"Quicksand"}>
					Escribe tu email de registro y te mandaremos un correo con instrucciones para recuperar tu contraseña.
				</DialogContentText>
				<TextField
					style={Quicksand}
					autoFocus
					margin="dense"
					id="emailRecovery"
					value={values.emailRecovery}
					onChange={handleChange('emailRecovery')}
					label="Email"
					type="email"
					fullWidth
					variant="standard"
				/>
				</DialogContent>
				<DialogActions>
					<Button style={Quicksand} onClick={handleClose}>Cancelar</Button>
					<Button style={Quicksand} onClick={handleRecovery}>Recuperar contraseña</Button>
				</DialogActions>
			</Dialog>
			<MediaQuery maxWidth={1224} onChange={handleMediaQueryChange}>
				<PapIndexMobile
					values={values}
					performLogin={performLogin}
					handleChange={handleChange}
					handleClickForgottenPass={handleClickOpen}
				/>
				<PapFooter
					isMobile={true}
				/>
			</MediaQuery>
			<MediaQuery minWidth={1224} onChange={handleMediaQueryChange}>
				<PapIndexDesktop
					values={values}
					performLogin={performLogin}
					handleChange={handleChange}
					handleClickForgottenPass={handleClickOpen}
				/>
				<PapFooter
					isMobile={false}
				/>
			</MediaQuery>
			<CookieConsent
				location="bottom"
				buttonText="Aceptar"
				acceptOnScroll={true}
				cookieName="pypCookies"
				style={{ 
					backgroundColor: '#000', 
					fontSize: '24px',
					lineHeight: '32px',
					fontFamily: 'Quicksand'
				}}
				buttonStyle={{ 
					fontSize: "20px",
					color: '#FFF',
					backgroundColor: '#2C90CB',
					fontSize: '24px',
					lineHeight: '32px',
					fontWeight: "700",
					borderRadius: '20px', 
					fontFamily: 'Quicksand'
				}}
				expires={150}
			>
				Esta web utiliza cookies para su correcto funcionamiento. Si continuas navegando, aceptas su uso. Puedes revisar la política de cookies <a style={cookieLinkStyle} href={'https://firebasestorage.googleapis.com/v0/b/premiumandpremium.appspot.com/o/documents%2FprivateDocuments%2FInformacion%20sobre%20el%20Uso%20de%20Cookies%20P%26P.pdf?alt=media&token=82f5eb74-c1b1-4606-846b-d89070e6bfc1'}>aquí</a>
			</CookieConsent>
		</main>
	);
};

export default IndexPage;
